import { graphql, useStaticQuery, Link, navigate } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import React, { useEffect, useRef, useState } from "react";
import { Button } from "./Button/Button";

// import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock";
import {
  header,
  headerWrap,
  about_expanded,
  about_expanded_col1,
  solutions_expanded,
  solutions_expanded_col1,
  insights_expanded,
  insights_expanded_col1,
  mobile_expanded,
  mobile_expanded_col1,
  active,
  image_container,
  latest_insights_container,
  latest_insight,
  latestInsightsImage,
  AMXLogo,
  hide,
  show,
  openCloseIcon,
  // navigationCanvas,
  expand,
  collapse,
  navWrapper,
} from "../styles/header.module.scss";
import { useMedia } from "../hooks/useMedia";

import AMXLogoDesktop from "../images/AMXLogoDesktop.svg";
import BlueNavShapesMobile from "../images/BlueNavShapesMobile.svg";
import BlueNavShapesDesktop from "../images/BlueNavShapesDesktop.svg";
import BlueNavShapesTablet from "../images/BlueNavShapesTablet.svg";
import YellowNavShapesMobile from "../images/YellowNavShapesMobile.svg";
import YellowNavShapesDesktop from "../images/YellowNavShapesDesktop.svg";
import YellowNavShapesTablet from "../images/YellowNavShapesTablet.svg";
import PinkNavShapesMobile from "../images/PinkNavShapesMobile.svg";
import PinkNavShapesDesktop from "../images/PinkNavShapesDesktop.svg";
import PinkNavShapesTablet from "../images/PinkNavShapesTablet.svg";
import BackIcon from "../images/BackIcon.svg";
import BurgerIcon from "../images/BurgerIcon.svg";
import CloseIcon from "../images/CloseIcon.svg";
import { useLocation } from "@reach/router";

const Header = () => {
  const location = useLocation();
  const { pathname } = location;
  const [activeNav, setActiveNav] = useState(null);

  const {
    strapiGlobal: { about_text, insights: images },
  } = useStaticQuery(graphql`
    {
      strapiGlobal {
        insights {
          image {
            image {
              alternativeText
              localFile {
                childImageSharp {
                  gatsbyImageData(
                    quality: 80
                    webpOptions: { quality: 80 }
                    width: 196
                    height: 240
                  )
                }
              }
            }
          }
          shortDesc
          slug
          id
        }
        about_text
      }
    }
  `);

  const { type } = useMedia(
    // Media queries
    ["(min-width: 1440px)", "(min-width: 800px)", "(min-width: 375px)"],
    // (relates to above media queries by array index)
    [
      {
        type: "desktop",
      },
      {
        type: "tablet",
      },

      { type: "mobile" },
    ],
    // Default object
    { type: "mobile" }
  );

  const handleNavOnClick = (navIndex) => {
    setActiveNav((prev) => {
      return prev === navIndex ? null : navIndex;
    });
  };
  const handleNavOnHover = (navIndex) => {
    if (type === "desktop" || type === "tablet") {
      setActiveNav(navIndex);
    }
  };

  const navigation = [
    {
      link: "#",
      text: "About",
      description:
        "Building the future of the institutional investment industry.",
      subMenu: [
        { title: "The AMX story", link: "/about/amx-story" },
        { title: "Our journey", link: "/articles/our-journey" },
        { title: "Contact us", link: "/about/contact-us" },
        { title: "Careers", link: "/about/careers" },
      ],
    },
    {
      link: "#",
      text: "Solutions",
      description: "",

      subMenu: [
        {
          title: "Who We Help",
          subMenu: [
            {
              title: "Investors",
              link: "/solutions/investors/pension-schemes",
            },
            { title: "Asset managers", link: "/solutions/asset-managers" },
            {
              title: "Consultants",
              link: "/solutions/consultants",
              subMenu: [
                { title: "Pension Schemes" },
                { title: "Insurance Companies" },
                { title: "Multinational Pension Schemes" },
              ],
            },

            { title: "Service partners", link: "/solutions/service-partners" },
          ],
        },
        {
          title: "What we do",
          subMenu: [
            // { title: "Investment strategies" },
            {
              title: "Third party management company",
              link: "/solutions/third-party-management-company",
            },
            {
              title: "Fund solutions",
              link: "/solutions/fund-solutions",
              subMenu: [
                { title: "Customised Fund Structure" },
                { title: "Tax Transparent Fund" },
              ],
            },

            {
              title: "Platform solutions",
              link: "/solutions/platform-solutions",
            },
            {
              title: "Investor stewardship service",
              link: "/solutions/investor-stewardship-service",
            },
            {
              title: "Additional services",
              link: "/solutions/additional-services/fund-structures",
              subMenu: [
                { title: "Liquidity Solutions" },
                { title: "Independent Risk Oversight" },
                { title: "Client Services" },
              ],
            },
          ],
        },
        {
          title: "Access our ecosystem",
          subMenu: [
            {
              title: "AMXConnect",
              link: "/solutions/amxconnect-overview",
              subMenu: [
                { title: "Overview" },
                { title: "Login" },
              ],
            },
            { title: "Login" },
          ],
        },
      ],
    },
    {
      link: "#",
      text: "Insights",
      styles: {},
      subMenu: [
        {
          title: "Latest insights",
          link: "/insights/latest-insights",
          subMenu: [],
        },
        {
          title: "Case studies",
          link: "/insights/case-studies",
          subMenu: [],
        },
        // { title: "Events", link: "/insights/events", subMenu: [] },
        {
          title: "Press releases",
          link: "/insights/press-release",
          subMenu: [],
        },
      ],
      latestInsights: [
        {
          image: "InsightsImage1.png",
          text: "Five questions with Versor Investments",
        },
        {
          image: "InsightsImage2.png",
          text: "KASH Is King: Can Covid teach us anything about learning?",
        },
      ],
    },
    {
      link: "#",
      text: "Mobile",
      styles: {},
      subMenu: [
        { title: "About" },
        { title: "Solutions" },
        { title: "Insights" },
      ],
    },
  ];

  // const targetRef = useRef();

  useEffect(() => {
    if (activeNav === "Mobile" && type !== "mobile") {
      setActiveNav(null);
    }
  }, [type]);

  // useEffect(() => {
  //   if (activeNav && type === "mobile") {
  //     disableBodyScroll(targetRef);
  //   } else {
  //     enableBodyScroll(targetRef);
  //   }
  // }, [activeNav]);

  return (
    <React.Fragment>
      {/* <header className={header} ref={targetRef}> */}
      <header className={header}>
        <div className={headerWrap}>
          <h1>
            <picture
              className={`${AMXLogo} ${
                type === "mobile" &&
                activeNav !== null &&
                activeNav !== "Mobile"
                  ? hide
                  : ""
              }`}
              onClick={() => navigate("/")}
            >
              <img src={AMXLogoDesktop} alt="AMX by Carne" />
            </picture>
          </h1>
          {type === "mobile" && activeNav !== null && activeNav !== "Mobile" ? (
            <img
              src={BackIcon}
              alt=""
              onClick={() => handleNavOnClick("Mobile")}
            />
          ) : null}
          <ul role="list">
            {navigation
              .filter((nav) => nav.text !== "Mobile")
              .map(({ text, link }) => {
                const isCurrentPath = pathname
                  .toLowerCase()
                  .includes(text.toLowerCase());
                return (
                  <li key={text} onMouseEnter={() => handleNavOnHover(text)}>
                    <a
                      href={link}
                      className={
                        text === activeNav
                          ? active
                          : isCurrentPath && !activeNav
                          ? active
                          : ""
                      }
                    >
                      {text}
                    </a>
                  </li>
                );
              })}
            <li>
              <a href="http://amxconnect.com">LOGIN</a>
            </li>
          </ul>

          {/* "Get in touch" button is disabled during active NAV in mobile*/}
          {type === "mobile" && activeNav !== null ? null : (
            <Button
              label="Get in touch"
              bkgColor="clear"
              handleOnClick={() => navigate("/about/get-in-touch")}
            ></Button>
          )}

          {/* Mobile Active Nav Name */}
          {type === "mobile" &&
            activeNav !== "Mobile" &&
            activeNav !== null && <p className="titles">{activeNav}</p>}

          {/* Mobile Burger Menu */}
          {type === "mobile" && (
            <img
              className={openCloseIcon}
              src={activeNav !== null ? CloseIcon : BurgerIcon}
              onClick={() => {
                setActiveNav((prev) => {
                  return typeof prev === "string" ? null : "Mobile";
                });
              }}
              alt=""
            />
          )}
        </div>
      </header>
      {/* <div className={activeNav ? navigationCanvas : ""}></div> */}

      <div className={`${navWrapper} ${activeNav ? expand : collapse}`}>
        <div
          className={`${about_expanded} ${
            activeNav === navigation[0].text ? show : hide
          }`}
          onMouseLeave={() => handleNavOnHover(null)}
        >
          <div className={about_expanded_col1}>
            <ul role="list">
              {navigation[0].subMenu.map(({ link, title }) => {
                return (
                  <li key={link}>
                    <Link to={link}>{title}</Link>
                  </li>
                );
              })}
            </ul>
            <h3>{about_text}</h3>
            {type === "mobile" && (
              <React.Fragment>
                <Button
                  label="Get in touch"
                  handleOnClick={() => navigate("/about/get-in-touch")}
                ></Button>
                <a href="http://amxconnect.com">LOGIN</a>
              </React.Fragment>
            )}
          </div>
          <picture className={image_container}>
            <source
              srcSet={YellowNavShapesDesktop}
              media="(min-width: 1440px)"
            />
            <source
              srcSet={YellowNavShapesDesktop}
              media="(min-width: 1280px)"
            />
            <source srcSet={YellowNavShapesTablet} media="(min-width: 768px)" />
            <source srcSet={YellowNavShapesMobile} media="(min-width: 375px)" />
            <img src={YellowNavShapesMobile} alt="NAV Shapes" />
          </picture>
        </div>

        <div
          className={`${solutions_expanded} ${
            activeNav === navigation[1].text ? show : hide
          }`}
          onMouseLeave={() => handleNavOnHover(null)}
        >
          <div className={`${solutions_expanded_col1}`}>
            {navigation[1].subMenu.map(({ title, link, subMenu }) => {
              return (
                <div key={link}>
                  <p>{title}</p>
                  <ul role="list">
                    {subMenu.map(({ link, title }) => (
                      <li key={link}>
                        <Link to={link}>{title}</Link>
                      </li>
                    ))}
                  </ul>
                </div>
              );
            })}
            {type === "mobile" && (
              <React.Fragment>
                <Button
                  label="Get in touch"
                  handleOnClick={() => navigate("/about/get-in-touch")}
                ></Button>
                <a href="http://amxconnect.com">LOGIN</a>
              </React.Fragment>
            )}
          </div>
          <picture className={image_container}>
            <source srcSet={BlueNavShapesDesktop} media="(min-width: 1440px)" />
            <source srcSet={BlueNavShapesDesktop} media="(min-width: 1280px)" />
            <source srcSet={BlueNavShapesTablet} media="(min-width: 768px)" />
            <source srcSet={BlueNavShapesMobile} media="(min-width: 375px)" />
            <img src={BlueNavShapesMobile} alt="NAV Shapes" />
          </picture>
        </div>

        <div
          className={`${insights_expanded} ${
            activeNav === navigation[2].text ? show : hide
          } `}
          onMouseLeave={() => handleNavOnHover(null)}
        >
          <div className={insights_expanded_col1}>
            <ul role="list">
              {navigation[2].subMenu.map(({ link, title, subMenu }) => {
                return (
                  <li key={link}>
                    <Link to={link}>{title}</Link>
                    <ul role="list">
                      {subMenu.map(({ link, title }) => (
                        <li key={link}>
                          <Link to={link}>{title}</Link>
                        </li>
                      ))}
                    </ul>
                  </li>
                );
              })}
            </ul>
            <div className={latest_insights_container}>
              {images
                .slice(0, 2)
                .map(({ slug, image, id, shortDesc }, index) => {
                  return (
                    <div
                      className={latest_insight}
                      key={id}
                      onClick={() => navigate(`/articles/${slug}`)}
                      role="button"
                      tabIndex={index}
                      onKeyDown={(event) => {
                        if (event.keycode === 13) navigate(`/articles/${slug}`);
                      }}
                    >
                      <GatsbyImage
                        image={
                          image.image.localFile.childImageSharp.gatsbyImageData
                        }
                        alt={image.image.alternativeText}
                        className={latestInsightsImage}
                      />
                      <h4>{shortDesc}</h4>
                    </div>
                  );
                })}
            </div>
            {type === "mobile" && (
              <React.Fragment>
                <Button
                  label="Get in touch"
                  handleOnClick={() => navigate("/about/get-in-touch")}
                ></Button>
                <a href="http://amxconnect.com">LOGIN</a>
              </React.Fragment>
            )}
          </div>
          <picture className={image_container}>
            <source srcSet={PinkNavShapesDesktop} media="(min-width: 1440px)" />
            <source srcSet={PinkNavShapesDesktop} media="(min-width: 1280px)" />
            <source srcSet={PinkNavShapesTablet} media="(min-width: 768px)" />
            <source srcSet={PinkNavShapesMobile} media="(min-width: 375px)" />
            <img src={PinkNavShapesMobile} alt="NAV Shapes" />
          </picture>
        </div>

        <div
          className={`${mobile_expanded} ${
            activeNav === navigation[3].text ? show : hide
          }`}
        >
          <div className={mobile_expanded_col1}>
            <ul role="list">
              {navigation[3].subMenu.map(({ link, title }) => {
                return (
                  <li key={link} onClick={() => handleNavOnClick(title)}>
                    <Link to={link}>{title}</Link>
                  </li>
                );
              })}
            </ul>
            <Button
              handleOnClick={() => navigate("/about/get-in-touch")}
              label="Get in touch"
            ></Button>
            <a href="http://amxconnect.com">LOGIN</a>
          </div>
          <div className={image_container}>
            <img src={BlueNavShapesMobile} alt="" />
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Header;
