import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import {
  social_icons_container,
  links_container,
  disclaimer_container,
  social_links_container,
} from "../styles/footer.module.css";

import LinkedInIcon from "../images/LinkedInIcon.svg";
import YouTubeIcon from "../images/YouTubeIcon.svg";
import TwitterIcon from "../images/TwitterIcon.svg";

const Footer = () => {
  const footerLinks = [
    { name: "Privacy notice", link: "/privacy-notice" },
    { name: "Legal and Regulatory", link: "/legal-and-regulatory" },
    { name: "UCITS", link: "/ucits" },
    { name: "Terms of use", link: "/terms-of-use" },
    { name: "Important notice", link: "/important-notice" },
  ];

  const { footerData } = useStaticQuery(
    graphql`
      {
        footerData: strapiGlobal {
          footerDisclaimer
          twitterLink
          youtubeLink
          linkedinLink
        }
      }
    `
  );

  const year = new Date().getFullYear();

  return (
    <footer>
      <div className={social_links_container}>
        <div className={social_icons_container}>
          <a href={footerData.linkedinLink} target="_blank">
            <img src={LinkedInIcon} alt="LinkedIn Icon" />
          </a>
          <a href={footerData.youtubeLink} target="_blank">
            <img src={YouTubeIcon} alt="YouTube Icon" />
          </a>
          <a href={footerData.twitterLink} target="_blank">
            <img src={TwitterIcon} alt="Twitter Icon" />
          </a>
        </div>
        <ul role="list" className={links_container}>
          {footerLinks.map((link, i) => (
            <li key={`footer-link-${i}`}>
              <a className="ui" href={link.link}>
                {link.name}
              </a>
            </li>
          ))}
        </ul>
      </div>

      <div className={disclaimer_container}>
        <p>{footerData.footerDisclaimer}</p>
      </div>

      <p>&copy; {year} AMX. Rights reserved.</p>
    </footer>
  );
};

export default Footer;
