import React from "react";
const styles = require("./Button.module.scss");
import { Link } from "gatsby";

export const Button = (props: any) => {
  const {
    label,
    icon = null,
    iconPosition = "left",
    bkgColor = "black",
    handleOnClick = null,
    type = "internalLink",
    className,
    toPage = "#",
    ...rest
  } = props;
  const colorClass = styles[bkgColor];

  return (
    <>
      {handleOnClick ? (
        <button
          onClick={handleOnClick}
          className={`${styles.button} ${colorClass} ${className}`}
          {...rest}
        >
          {icon && iconPosition === "left" ? (
            <span className={`${styles.icon}`}>
              <img src={icon} alt="" />
            </span>
          ) : null}
          {label}
        </button>
      ) : type === "externalLink" ? (
        <a
          className={`${styles.button} ${colorClass}`}
          href={toPage}
          target="_blank"
        >
          {label}
        </a>
      ) : type === "internalLink" ? (
        <Link className={`${styles.button} ${colorClass}`} to={toPage}>
          {icon && iconPosition === "left" ? (
            <span className={`${styles.icon} `}>
              <img src={icon} alt="" />
            </span>
          ) : null}
          {label}
        </Link>
      ) : null}
    </>
  );
};
