// extracted by mini-css-extract-plugin
export var AMXLogo = "header-module--AMXLogo--K37rd";
export var about_expanded = "header-module--about_expanded--FI-WU";
export var about_expanded_col1 = "header-module--about_expanded_col1--Mj4Zg";
export var active = "header-module--active---mgDZ";
export var collapse = "header-module--collapse--m8iPF";
export var expand = "header-module--expand--uYl1-";
export var header = "header-module--header--SPj1F";
export var headerWrap = "header-module--headerWrap--x+hYy";
export var hide = "header-module--hide--TgOz7";
export var image_container = "header-module--image_container--m7zMS";
export var insights_expanded = "header-module--insights_expanded--2oSi0";
export var insights_expanded_col1 = "header-module--insights_expanded_col1--GQooF";
export var latestInsightsImage = "header-module--latestInsightsImage--Vw4mH";
export var latest_insight = "header-module--latest_insight--Lhntm";
export var latest_insights_container = "header-module--latest_insights_container--zkEit";
export var mobile_expanded = "header-module--mobile_expanded--6LxS3";
export var mobile_expanded_col1 = "header-module--mobile_expanded_col1--mw4GN";
export var navWrapper = "header-module--navWrapper--6cDza";
export var navigationCanvas = "header-module--navigationCanvas--TjE3u";
export var openCloseIcon = "header-module--openCloseIcon--qM6wU";
export var page404Title = "header-module--page404-title--w67n9";
export var show = "header-module--show--4ABnA";
export var solutions_expanded = "header-module--solutions_expanded--6jRFV";
export var solutions_expanded_col1 = "header-module--solutions_expanded_col1--yLi-7";