import React from "react";
import PropTypes from "prop-types";
import Seo from "./seo";

import Header from "./Header";
import Footer from "./Footer";
const Layout = (props: { children: any; seo?: any }) => {
  const { children, seo } = props;
  return (
    <>
      <Header />

      <Seo seo={seo} />
      <main>{children}</main>
      <Footer />
    </>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
