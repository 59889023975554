// extracted by mini-css-extract-plugin
export var black = "Button-module--black--yxnsj";
export var blackOnBlack = "Button-module--blackOnBlack--zjUit";
export var blackOnLight = "Button-module--blackOnLight--FFDBZ";
export var button = "Button-module--button--sRE36";
export var clear = "Button-module--clear--5phnw";
export var clearOnLight = "Button-module--clearOnLight--zZaUL";
export var icon = "Button-module--icon--T6Gfz";
export var page404Title = "Button-module--page404-title--RCgCU";
export var phoneIcon = "Button-module--phoneIcon--zh+IH";
export var phoneIconWhite = "Button-module--phoneIconWhite--tGNyq";
export var playIconClear = "Button-module--playIconClear--rcaSY";
export var white = "Button-module--white--mBHt3";
export var whiteOnBlack = "Button-module--whiteOnBlack--ukz5P";